import { AxiosError } from "axios";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AuthError } from "../types/AuthModel";
import HTTPClient from "../util/HttpClient";
import { JASMINE_ENDPOINT } from "../util/consts";

export enum AuthStatus {
    Loading = "loading",
    Authenticated = "authenticated",
    Unauthenticated = "unauthenticated",
    Failed = "failed",
}

type voidable = () => void;
type asyncVoidable = () => void;

export type AuthenticationState = {
    authStatus: AuthStatus;
    email: string;
    setUnauthenticated: voidable;
    setAuthenticated: voidable;
    setFailed: voidable;
    setLoading: voidable;
    checkAuthStatus: asyncVoidable;
};

export const useAuthStore = create<AuthenticationState>()(
    devtools(
        persist(
            (set) => ({
                authStatus: AuthStatus.Loading,
                email: "",
                setUnauthenticated: () => set({ authStatus: AuthStatus.Unauthenticated }),
                setAuthenticated: () => set({ authStatus: AuthStatus.Authenticated }),
                setFailed: () => set({ authStatus: AuthStatus.Failed }),
                setLoading: () => set({ authStatus: AuthStatus.Loading }),
                checkAuthStatus: async () => {
                    const Client: HTTPClient = new HTTPClient(JASMINE_ENDPOINT);

                    try {
                        const resp = await Client.post("/api/e2");
                        if (resp.status >= 200 && resp.status < 300) {
                            set({ authStatus: AuthStatus.Authenticated });
                            set({ email: (resp.data as any)["email"] });
                        }
                    } catch (err) {
                        const error = err as AxiosError;
                        if (!err) {
                            return;
                        }
                        const errData = error.response?.data as AuthError;
                        set({ authStatus: AuthStatus.Unauthenticated });
                        if (errData && errData.ac && !errData.hPr) {
                            set({ authStatus: AuthStatus.Failed });
                        }
                    }
                },
            }),
            {
                name: "auth-storage",
            }
        )
    )
);
