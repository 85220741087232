import type { AppProps } from "next/app"
import "../styles/globals.sass"
import Head from "next/head"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import JasminePageLayout from "../components/page-layouts/JasminePageLayout"
import { AuthStatus, useAuthStore } from "../stores/useAuthStore"
import { JASMINE_ENDPOINT } from "../util/consts"

function MyApp({ Component, pageProps }: AppProps) {
    const [path, setPath] = useState("")

    const authenticated = useAuthStore((state) => state.authStatus === AuthStatus.Authenticated)
    const unauthenticated = useAuthStore((state) => state.authStatus === AuthStatus.Unauthenticated)
    const authFailure = useAuthStore((state) => state.authStatus === AuthStatus.Failed)
    const checkAuth = useAuthStore((state) => state.checkAuthStatus)

    const router = useRouter()

    useEffect(() => {
        if (!router.isReady) return
        const newPath = router.asPath
        let update = ""

        if (newPath === "/login") {
            update = "Login | "
        } else if (newPath.includes("search")) {
            update = "Search | "
        } else if (newPath.includes("summary")) {
            update = "Summary | "
        } else if (newPath.includes("article")) {
            update = "Article | "
        } else if (newPath === "/about") {
            update = "About | "
        } else if (newPath === "/cite") {
            update = "Citator | "
        } else if (newPath === "/logout") {
            update = "Logout | "
        } else if (newPath.includes("/barrister")) {
            update = "Barrister | "
        } else if (newPath.includes("/library")) {
            update = "Library | "
        }

        if (newPath !== "/logout" && newPath !== "/login" && !newPath.includes("/oauth/callback")) {
            checkAuth()
        }

        setPath(update)
    }, [router])

    useEffect(() => {
        const handleAuthFailure = async () => {
            if (router.asPath.includes("/oauth/callback") || router.asPath.includes("/about")) return
            if (unauthenticated || authFailure) {
                if (router.asPath.startsWith("/login") || router.asPath.startsWith("/logout")) return
                await router.push("/logout")
            }
        }

        handleAuthFailure()
    }, [unauthenticated, authFailure, router])

    return (
        <JasminePageLayout path={router.asPath} authenticated={authenticated}>
            <Head>
                <title>{`${path} Jasmine`}</title>
            </Head>
            <Component {...pageProps} />
            {/*
                 <Script strategy="lazyOnload" src={'scripts/drift.js'}/>
                 */}
        </JasminePageLayout>
    )
}

export default MyApp
