import Image from "next/image"
import React, { FunctionComponent } from "react"
import JasmineLogo from "../../public/logos/jasmine.svg"

interface JasmineFooterProps {
    unauthenticated?: boolean;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            ["jasmine-footer"]: unknown;
        }
    }
}

const explore: { name: string; href: string }[] = [
    {
        name: "About",
        href: "/about",
    },
    {
        name: "Search",
        href: "/search",
    },
    // {
    //     name: "Cite",
    //     href: "/cite",
    // },
    // {
    //     name: "Barrister Search",
    //     href: "/barrister",
    // },
]

const legal: { name: string; href: string }[] = [
    {
        name: "Terms of Use",
        href: "https://jade.io/j/?c=terms+of+use",
    },
    {
        name: "Privacy Statement",
        href: "https://jade.io/j/?c=privacy+statement",
    },
]

const related: { name: string; href: string }[] = [
    {
        name: "JADE",
        href: "https://openlaw.com.au/projects/jade",
    },
    {
        name: "Victorian Reports",
        href: "https://openlaw.com.au/projects/victorian-reports",
    },
    {
        name: "Open Law",
        href: "https://openlaw.com.au",
    },
]
export const JasmineFooter: FunctionComponent<JasmineFooterProps> = ({ unauthenticated }) => {
    return (
        <jasmine-footer is='div'>
            <footer className={`${unauthenticated ? "bg-cool-gray" : "bg-[#f4f9f8]"} py-12`}>
                <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                    <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
                        <div className='xl:mt-0 md:mx-12 grid grid-cols-1 col-span-2'>
                            <div className='mb-16'>
                                <Image
                                    width={96}
                                    height={96}
                                    src={"/logos/jasmine.svg"}
                                    alt='Jasmine logo'
                                    className='-ml-6'
                                />
                                <p className='text-base font-sourceSansPro text-jade-primary'>
                                    JADE is Australia{`'`}s best legal research tool which is purpose built for
                                    researching Australian Law.
                                </p>
                            </div>
                            <div className='flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-24'>
                                <div>
                                    <h3
                                        className={`text-base font-bold font-sourceSansPro text-${
                                            unauthenticated ? "jade-primary" : "jade-primary"
                                        }`}
                                    >
                                        Explore
                                    </h3>
                                    <ul role='list' className='mt-4 space-y-4'>
                                        {explore.map((item) => (
                                            <li key={item.name}>
                                                <a
                                                    href={item.href}
                                                    className='text-base text-jade-primary font-sourceSansPro hover:underline hover:decoration-2'
                                                >
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <h3
                                        className={`text-base font-bold font-sourceSansPro text-${
                                            unauthenticated ? "jade-primary" : "jade-primary"
                                        }`}
                                    >
                                        Legal
                                    </h3>
                                    <ul role='list' className='mt-4 space-y-4'>
                                        {legal.map((item) => (
                                            <li key={item.name}>
                                                <a
                                                    href={item.href}
                                                    className='text-base text-jade-primary font-sourceSansPro hover:underline hover:decoration-2'
                                                >
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className=''>
                                    <h3
                                        className={`text-base font-bold font-sourceSansPro text-${
                                            unauthenticated ? "jade-primary" : "jade-primary"
                                        }`}
                                    >
                                        Related Projects
                                    </h3>
                                    <ul role='list' className='mt-4 space-y-4'>
                                        {related.map((item) => (
                                            <li key={item.name}>
                                                <a
                                                    href={item.href}
                                                    className='text-base text-jade-primary font-sourceSansPro hover:underline hover:decoration-2'
                                                >
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-12 mx-6 md:mx-16 border-t border-jade-primary pt-8`}></div>
                <div className='mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8'>
                    <div className='flex justify-center space-x-6'>
                        {[].map((item: any) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className='text-jade-primary hover:underline hover:decoration-2 hover:text-gray hover:font-bold'
                            >
                                <span className='sr-only'>{item.name}</span>
                                <item.icon className='h-6 w-6' aria-hidden='true' />
                            </a>
                        ))}
                    </div>
                    <p className='font-sourceSansPro text-center text-base text-jade-primary'>
                        &copy; 2000 - {new Date().getFullYear()} BarNetwork Pty Limited (ABN 32 092 121 198). All rights
                        reserved.
                    </p>
                </div>
            </footer>
        </jasmine-footer>
    )
}

export default JasmineFooter
